import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Body from '../components/layout/Body';
import ResponsiveContainer from '../components/layout/ResponsiveContainer';
import RelativeWrapper from '../components/layout/RelativeWrapper';
import ContentWrapper from '../components/layout/ContentWrapper';
import TextHeadingWrapper from '../components/layout/TextHeadingWrapper'

import SEO from '../components/SEO';

import SeenEnough from '../components/cta/SeenEnough';
import FeatureListBreaker from '../components/cta/FeatureListBreaker';
import SectorListBreaker from '../components/cta/SectorListBreaker';

import Benefit from '../components/section/Benefit';
import TestimonialCarousel from '../components/section/TestimonialCarousel';
import ClientLogoBar, { ClientLogos } from '../components/section/ClientLogoBar';

import { ViewportBreakpoints } from '../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2'
import H4 from '@rotaready/frecl/build/H4';
import Button from '@rotaready/frecl/build/Button';
import Text from '@rotaready/frecl/build/Text';

const OverflowHidden = styled.div`
  overflow: hidden;
`;

const Hero = styled.div`
  margin: 80px 0 60px 0;
  position: relative;
  overflow: visible;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 120px 0 40px 0;
  }
`;

const HeroInnerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
    align-self: center;
  }
`;

const H1Extended = styled(H1)`
  font-size: 38px;
  line-height: 54px;
  margin-bottom: 30px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    font-size: 46px;
    line-height: 64px;
    margin-bottom: 20px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const DiagonalStripes = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  top: ${({ tabletTop }) => `${tabletTop}px`};
  width: 100%;
  z-index: 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    top: ${({ desktopTop }) => `${desktopTop}px`};
  }
`;

const DiagonalStripe = styled.span`
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 100px 0 rgba(71,91,104,0.2);
  width: 250%;
  height: 150px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 350px;
  }
`;

const DarkStripe = styled(DiagonalStripe)`
  background-color: rgba(13, 81, 255, 0.5);
  transform: rotate(-45deg);
  left: ${({ tabletLeft }) => tabletLeft ? `${tabletLeft}px` : 'unset'};
  right: ${({ tabletRight }) => tabletRight ? `${tabletRight}px` : 'unset'};

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    left: ${({ desktopLeft }) => desktopLeft ? `${desktopLeft}px` : 'unset'};
    right: ${({ desktopRight }) => desktopRight ? `${desktopRight}px` : 'unset'};
  }
`;

const LightStripe = styled(DiagonalStripe)`
  background-color: ${({ theme: { colors } }) => colors.white};
  transform: rotate(45deg);
  left: ${({ tabletLeft }) => tabletLeft ? `${tabletLeft}px` : 'unset'};
  right: ${({ tabletRight }) => tabletRight ? `${tabletRight}px` : 'unset'};

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    left: ${({ desktopLeft }) => desktopLeft ? `${desktopLeft}px` : 'unset'};
    right: ${({ desktopRight }) => desktopRight ? `${desktopRight}px` : 'unset'};
  }
`;

const Testimonials = styled.div`
  padding: 40px 0;
  position: relative;
  overflow: visible;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0 40px 0;
  }
`;

const TestimonialsWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
  overflow: hidden;
`;

const LogoBarWrapper = styled.div`
  margin-top: 50px;
`;

const IntroSection = styled.div`
  text-align: center;
  padding: 20px 0 60px 0;

  > p {
    margin-bottom: 20px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px 0 100px 0;
    width: 60%;
    margin: 0 auto;
  }
`;

const SectionTitle = styled(H2)`
  margin-bottom: 60px;
`;

const IndexPage = ({ data }) => (
  <Body>
    <SEO
      title="Rotaready | Rota Software for Hospitality, Leisure & Retail"
      description="Award wining cloud-based rota software that can predict your sales and allocate your shifts automatically. Get an accurate staff rota in just a few clicks."
    />
    <OverflowHidden>
      <ResponsiveContainer>
        <Hero>
          <DiagonalStripes tabletTop={475} desktopTop={0}>
            <LightStripe tabletLeft={-320} desktopLeft={-550} />
            <DarkStripe tabletLeft={-110} desktopLeft={-240} />
          </DiagonalStripes>

          <HeroInnerContainer>
            <HeroBody>
              <H1Extended uistyle="brand160" text="Award winning rota software" />

              <H4 uistyle="grey">
                Discover rota software specially developed for hospitality, leisure and retail. Rotaready helps you schedule rotas, optimise wage spend, record attendance and approve timesheets for payroll.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="primary" text="Contact support" size="lg" ghost />
                </Link>
              </CtaButtons>
            </HeroBody>

            <HeroIllustration>
              <GatsbyImage image={data.heroImage.childImageSharp.gatsbyImageData} alt="Rotaready rota software" />
            </HeroIllustration>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </OverflowHidden>

    <LogoBarWrapper>
      <ClientLogoBar
        logos={[
          ClientLogos.HICKORYS_SMOKEHOUSE,
          ClientLogos.GUSTO,
          ClientLogos.PIZZA_PILGRIMS,
          ClientLogos.BREWDOG,
          ClientLogos.WARNER_LEISURE_HOTELS,
          ClientLogos.MOLLIES,
          ClientLogos.DISHOOM,
          ClientLogos.NQ64,
          ClientLogos.CREAMS,
          ClientLogos.HUBBOX,
          ClientLogos.TOPGOLF,
          ClientLogos.NAMCO,
        ]}
      />
    </LogoBarWrapper>

    <Benefit
      mirrorArrange
      title="Rota software designed for hospitality, leisure and retail"
      description="Whether you work in the head office, behind the bar, on the shop floor, or anywhere in between, Rotaready offers a whole host of features designed just for you."
      benefitVideo={
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Jh3s_rl3PCI?si=84qAVccPtpTb5wZb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      }
      quote="With Rotaready we're reaping huge benefits on staff engagement and service quality improvements across all our bars."
      quoteAttribution="James Brown, BrewDog"
      quoteImage={data.brewdogQuoteLogo.childImageSharp.gatsbyImageData}
    />

    <Benefit
      backgroundColor="grey10"
      title="Remove the guesswork from rotas and forecasting"
      description="Future-proof your business with cloud-based rota software that can predict your sales and allocate your shifts automatically, giving you an accurate staff rota in just a few clicks."
      illustrationImage={data.generatedShiftsIllustration.childImageSharp.gatsbyImageData}
      illustrationAltText="Automatically building a staff rota"
      quote="The built-in intelligence is the main reason we chose Rotaready in our hotels and restaurants. It saves us an enormous amount of time."
      quoteAttribution="David Tracey, Manorview Hotels"
      quoteImage={data.manorviewQuoteLogo.childImageSharp.gatsbyImageData}
    />

    <Benefit
      mirrorArrange
      title="Foolproof user interface"
      description="Rota scheduling software only works if everyone buys into it. That's why we have a team dedicated to making Rotaready easy to use, intuitive and simple to set up."
      illustrationImage={data.uiBalloonsIllustration.childImageSharp.gatsbyImageData}
      illustrationAltText="Management screen with balloons"
      quote="Finally, a rota system that's super easy to use, accurate and fun. Our staff picked it up without needing any training."
      quoteAttribution="Jean Johnson, Topgolf"
      quoteImage={data.topgolfQuoteLogo.childImageSharp.gatsbyImageData}
    />

    <SeenEnough headingText="Like Rotaready already?" />

    <Benefit
      title="Optimise your schedules and your decision-making"
      description="With full visibility and control over wage costs, revenue and intelligent forecasts, Rotaready helps you optimise your labour spend and make better business decisions."
      illustrationImage={data.demandForecastingIllustration.childImageSharp.gatsbyImageData}
      illustrationAltText="Forecasting future sales within Rotaready"
      quote="Our pub managers now plan their rotas more cost-effectively, thanks to live & accurate staff wage costs."
      quoteAttribution="Leanne Day-Sheriff, Golden Lion Group"
      quoteImage={data.glgQuoteLogo.childImageSharp.gatsbyImageData}
    />

    <Benefit
      mirrorArrange
      backgroundColor="grey10"
      title="The Rotaready team is your team"
      description="Before Rotaready, we all had jobs in bars, restaurants and retail stores, so we know how busy things get. We'll work together on a roll-out plan that suits you. And with live chat built-in to our rota software, our friendly UK team is just a chat or call away."
      illustrationImage={data.supportChatIllustration.childImageSharp.gatsbyImageData}
      illustrationAltText="Live chat with customer support"
      quote="I'm delighted with the software and the people are first class. Rotaready really understand their product and exactly what people want from it."
      quoteAttribution="Daniel Evans, Stormfront"
      quoteImage={data.stormfrontQuoteLogo.childImageSharp.gatsbyImageData}
    />

    <RelativeWrapper backgroundColor="white">
      <FeatureListBreaker 
        displayCopy 
        isHomepage 
        rows={2} 
        customLinkText={{
          ROTA_SCHEDULING: "Explore rota scheduling software",
          TIME_ATTENDANCE: "Explore time & attendance feature",
          HR: "Explore HR feature",
          PAYROLL: "Explore payroll feature",
          DEMAND_FORECASTING: "Explore demand forecasting feature",
        }}
        />
    </RelativeWrapper>

    <TestimonialsWrapper>
      <ResponsiveContainer>
        <Testimonials>
          <DiagonalStripes tabletTop={-10} desktopTop={0}>
            <LightStripe tabletRight={-200} desktopRight={-100} />
            <DarkStripe tabletRight={-110} desktopRight={-400} />
          </DiagonalStripes>

          <TestimonialCarousel />
        </Testimonials>
      </ResponsiveContainer>
    </TestimonialsWrapper>

    <RelativeWrapper backgroundColor="white">
      <SectorListBreaker displayCopy rows={1} />
    </RelativeWrapper>



    <RelativeWrapper backgroundColor="white">
      <ResponsiveContainer>
        <IntroSection>
          <SectionTitle uistyle="brand160" text="Ready to get started with rota management software?" />
          <Text size="xl">
            Let us know what you're looking for below and one of our team will be in touch.
          </Text>
          <CtaButtons>
            <Link to="/demo">
              <Button uistyle="primary" text="Request demo" size="lg" />
            </Link>
            <Link to="/contact">
              <Button uistyle="brand160" text="Contact support" size="lg" ghost />
            </Link>
          </CtaButtons>
        </IntroSection>
      </ResponsiveContainer>
    </RelativeWrapper>
  </Body>
);

export default IndexPage;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "illustrations/index-hero.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, width:768)
      }
    }

    clockInIllustration: file(relativePath: { eq: "illustrations/mobile-clock-in.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, width:560)
      }
    }

    generatedShiftsIllustration: file(relativePath: { eq: "illustrations/generated-shifts.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, width:519)
      }
    }

    uiBalloonsIllustration: file(relativePath: { eq: "illustrations/ui-balloons.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, width:560)
      }
    }

    demandForecastingIllustration: file(relativePath: { eq: "illustrations/demand-forecasting.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, width:569)
      }
    }

    supportChatIllustration: file(relativePath: { eq: "illustrations/support-live-chat.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, width:342)
      }
    }

    brewdogQuoteLogo: file(relativePath: { eq: "testimonial/logo-brewdog.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, height:20)
      }
    }

    glgQuoteLogo: file(relativePath: { eq: "testimonial/logo-glg.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, height:40)
      }
    }

    topgolfQuoteLogo: file(relativePath: { eq: "testimonial/logo-topgolf.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, height:20)
      }
    }

    manorviewQuoteLogo: file(relativePath: { eq: "testimonial/logo-manorview.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, height:40)
      }
    }

    stormfrontQuoteLogo: file(relativePath: { eq: "testimonial/logo-stormfront.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100, height:24)
      }
    }
  }
`;
