import React, { useRef } from 'react';
import Slider from 'react-slick';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Testimonial from './Testimonial';

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.brand};
  box-sizing: border-box;

  .slick-active & {
    background-color: ${({ theme: { colors } }) => colors.brand};
  }
`;

const DotHitArea = styled.div`
  padding: 10px 5px;
  cursor: pointer;
`;

const DotList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
  }
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
`;

const DirectionArrow = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 0 10px;
  line-height: 30px;
`;

const ChevronIconLeft = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'chevron-left'],
})`
  color: ${({ theme: { colors } }) => colors.brand};
  font-size: 16px;
`;

const ChevronIconRight = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'chevron-right'],
})`
  color: ${({ theme: { colors } }) => colors.brand};
  font-size: 16px;
`;

const TestimonialCarousel = () => {
  const slider = useRef(null);

  const next = () => {
    slider.current.slickNext();
  };

  const prev = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    appendDots: dots => (
      <DotContainer>
        <DirectionArrow onClick={prev}>
          <ChevronIconLeft />
        </DirectionArrow>
        <DotList>{dots}</DotList>
        <DirectionArrow onClick={next}>
          <ChevronIconRight />
        </DirectionArrow>
      </DotContainer>
    ),
    customPaging: () => (
      <DotHitArea>
        <Dot />
      </DotHitArea>
    ),
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          brewdogPhoto: file(relativePath: { eq: "testimonial/brewdog.png" }) {
            childImageSharp {
              gatsbyImageData(quality:100, width:580)
            }
          }

          brewdogLogo: file(relativePath: { eq: "testimonial/logo-brewdog.png" }) {
            childImageSharp {
              gatsbyImageData(quality:100, height:20)
            }
          }

          warnerPhoto: file(relativePath: { eq: "testimonial/warner-leisure-hotels.png" }) {
            childImageSharp {
              gatsbyImageData(quality:100, width:580)
            }
          }

          warnerLogo: file(relativePath: { eq: "testimonial/logo-warner.png" }) {
            childImageSharp {
              gatsbyImageData(quality:100, height:40)
            }
          }

          manorviewPhoto: file(relativePath: { eq: "testimonial/manorview.png" }) {
            childImageSharp {
              gatsbyImageData(quality:100, width:580)
            }
          }

          manorviewLogo: file(relativePath: { eq: "testimonial/logo-manorview.png" }) {
            childImageSharp {
              gatsbyImageData(quality:100, height:40)
            }
          }
        }
      `}
      render={data => {
        return (
          <Slider ref={slider} {...settings}>
            <div>
              <Testimonial
                image={data.brewdogPhoto.childImageSharp.gatsbyImageData}
                quoteLine1="With Rotaready we're reaping huge benefits on staff engagement and service quality improvements. Our bar managers are getting their rotas right first time, with greater focus on commercial performance."
                quoteLine2="We're delighted to work with a company committed to continual improvement through dialogue with its customers."
                quoteAttribution="James Brown, BrewDog"
                logo={data.brewdogLogo.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <Testimonial
                image={data.warnerPhoto.childImageSharp.gatsbyImageData}
                quoteLine1="Rotaready is that rare thing: a tech company that has kept its promises and made an immediate positive impact."
                quoteLine2="The fact we quickly switched it on across all our sites is testament to this and we're excited about how the partnership will continue to deliver value to our business, team and guests in the coming years."
                quoteAttribution="James Johnson, Warner Leisure Hotels"
                logo={data.warnerLogo.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <Testimonial
                image={data.manorviewPhoto.childImageSharp.gatsbyImageData}
                quoteLine1="Rotaready gives us a real time view of performance across all our hotels and restaurants. Now we keep service levels tracked against what's expected - we used to always be behind, now we're always ahead."
                quoteLine2="The built-in intelligence is the main reason we chose Rotaready. It saves us an enormous amount of time. If Apple made rota scheduling software, this would be it!"
                quoteAttribution="David Tracey, Manorview Hotels"
                logo={data.manorviewLogo.childImageSharp.gatsbyImageData}
              />
            </div>
          </Slider>
        );
      }}
    />
  );
};

export default TestimonialCarousel;
