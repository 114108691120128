import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import HospitalityIcon from '../../images/svg/icons/industry-hospitality.svg';
import LeisureIcon from '../../images/svg/icons/industry-leisure.svg';
import RetailIcon from '../../images/svg/icons/industry-retail.svg';

import { ViewportBreakpoints } from '../cssConstants';
import ContentWrapper from '../layout/ContentWrapper';
import ResponsiveContainer from '../layout/ResponsiveContainer';
import TextHeadingWrapper from '../layout/TextHeadingWrapper';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import TextLink from '@rotaready/frecl/build/TextLink';

const Container = styled(FlexContainer).attrs({
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'row',
  flexWrap: props => !!props.rows,
})`
  > div {
    flex: 1 100%;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      flex: 1 calc(${({ rows }) => rows === 2 ? 33 : 16}% - 40px);
    }
  }
`;

const ItemContainer = styled.div`
  margin: 20px;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 0 4px 1px ${({ theme: { colors } }) => colors.boxshadowmedium};
    border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  }
`;

const IconContainer = styled.div`
  margin: 0 auto 20px auto;
  width: 40px;
  height: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 50px;
    height: 50px;
  }
`;

const TextContainer = styled.div`
  padding-top: 20px;
`;

const LinkContainer = styled.div`
  padding-top: 20px;
  margin-top: auto;
`;

export const Sectors = Object.freeze({
  HOSPITALITY: 1,
  LEISURE: 2,
  RETAIL: 3,
});

class SectorListItem extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subCopy: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    displayCopy: PropTypes.bool.isRequired,
  };

  handleOnClick = () => {
    const {
      url,
    } = this.props;

    navigate(url);
  };

  render() {
    const {
      title,
      subCopy,
      displayCopy,
      Icon,
    } = this.props;

    return (
      <ItemContainer onClick={this.handleOnClick}>
        <IconContainer>
          <Icon />
        </IconContainer>
        <H4 text={title} uistyle="brand160" />
        {displayCopy
        && (
          <TextContainer>
            <Text text={subCopy} uistyle="grey" />
          </TextContainer>
        )}
        <LinkContainer>
          <TextLink
            text="Explore more"
            weight="bold"
            render={props => <span {...props} />}
          />
        </LinkContainer>
      </ItemContainer>
    );
  }
}

const SectorListBreaker = ({
  displayCopy,
  excludeFeature,
  headingText,
  rows,
}) => (
  <ResponsiveContainer>
    <ContentWrapper>
      <TextHeadingWrapper>
        <H2 text={headingText} uistyle="brand160" />
      </TextHeadingWrapper>

      <Container rows={rows}>
        {excludeFeature !== Sectors.HOSPITALITY
        && (
          <SectorListItem
            url="/hospitality"
            title="Hospitality"
            subCopy="Rota management software for pubs, bars, restaurants and hotels."
            displayCopy={displayCopy}
            Icon={HospitalityIcon}
          />
        )}

        {excludeFeature !== Sectors.LEISURE
        && (
          <SectorListItem
            url="/leisure"
            title="Leisure"
            subCopy="Rota software for gyms, museums, theatres, golf clubs and everything in between."
            displayCopy={displayCopy}
            Icon={LeisureIcon}
          />
        )}

        {excludeFeature !== Sectors.RETAIL
        && (
          <SectorListItem
            url="/retail"
            title="Retail"
            subCopy="A rota management system for small shops and large retail chains."
            displayCopy={displayCopy}
            Icon={RetailIcon}
          />
        )}
        
      </Container>
    </ContentWrapper>
  </ResponsiveContainer>
);

SectorListBreaker.propTypes = {
  displayCopy: PropTypes.bool,
  excludeFeature: PropTypes.oneOf(Object.values(Sectors)),
  rows: PropTypes.oneOf([1, 2]),
  headingText: PropTypes.string,
};

SectorListBreaker.defaultProps = {
  displayCopy: false,
  excludeFeature: undefined,
  rows: 1,
  headingText: 'Rota software for your industry',
};

export default SectorListBreaker;
