import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';

import QuotationMark from '../../frills/QuotationMark';

import { ViewportBreakpoints } from '../../cssConstants';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 45%;
  margin-bottom: 40px;

  .gatsby-image-wrapper {
    max-width: 90%;
    margin: 0 auto;
  }

  img {
    border-radius: 3px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 0;

    .gatsby-image-wrapper {
      max-width: 560px;
    }
  }
`;

const QuoteContainer = styled.div`
  flex: 0 0 45%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    align-items: unset;
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  flex: 0 0 auto;
`;

const Quote = styled.div`
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;

  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    text-align: left;
  }
`;

const QuoteText = styled(H4).attrs({
  uistyle: 'brand160',
})`
  flex: 0 0 auto;
`;

const QuoteTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const QuoteAttribution = styled(Text)`
  font-size: 18px;
`;

const Testimonial = ({
  image,
  quoteLine1,
  quoteLine2,
  quoteAttribution,
  logo,
}) => (
  <Container>
    <ImageContainer>
      <GatsbyImage
        image={image}
        alt="Testimonial photo"
      />
    </ImageContainer>
    <QuoteContainer>
      <QuotationMark width={60} />

      <Quote>
        <QuoteTextWrapper>
          <QuoteText text={quoteLine1} />
        </QuoteTextWrapper>
        {quoteLine2 && (
          <QuoteTextWrapper>
            <QuoteText text={quoteLine2} />
          </QuoteTextWrapper>
        )}
        <QuoteAttribution text={quoteAttribution} />
      </Quote>

      <LogoContainer>
        <GatsbyImage
          image={logo}
          alt="Testimonial logo"
        />
      </LogoContainer>
    </QuoteContainer>
  </Container>
);

Testimonial.propTypes = {
  image: PropTypes.shape({}).isRequired,
  quoteLine1: PropTypes.string.isRequired,
  quoteLine2: PropTypes.string,
  quoteAttribution: PropTypes.string.isRequired,
  logo: PropTypes.shape({}).isRequired,
};

Testimonial.defaultProps = {
  quoteLine2: '',
};

export default Testimonial;
